import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { pathOr } from 'ramda'
import styled from 'styled-components'
import {
  validateRegistrationInvitedField,
  validateRegistrationInvitedValues
} from 'src/features/auth/duck/schema'
import { withPossessiveS } from 'src/utils/string'
import { META } from 'src/utils/seo'
import { jobFunctionOptions } from 'src/utils/jobFunction'

import {
  acceptOrganisationInviteRoutine,
  getOrganisationInviteRoutine,
  removeOrganisationInviteDataRoutine
} from 'src/features/auth/duck/actions'
import {
  getOrganisationInvite,
  isAuthLoading
} from 'src/features/auth/duck/selectors'

import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Select from 'src/components/atoms/Select'
import Metadata from 'src/components/Metadata'
import Button from 'src/components/atoms/Button'
import Loader from 'src/components/atoms/Loader'

const emptyValues = {
  password: '',
  firstName: '',
  lastName: '',
  jobFunction: ''
}

export const SignUpForm = props => {
  const { organisationInviteId } = props
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isAuthLoading)
  const organisationInvite = useSelector(getOrganisationInvite)
  const fetchOrganisationInvite = useCallback(
    payload => dispatch(getOrganisationInviteRoutine(payload)),
    [dispatch]
  )
  const removeOrganisationInviteData = useCallback(
    () => dispatch(removeOrganisationInviteDataRoutine()),
    [dispatch]
  )
  const acceptOrganisationInvite = useCallback(
    payload => dispatch(acceptOrganisationInviteRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    fetchOrganisationInvite(organisationInviteId)
    return () => {
      removeOrganisationInviteData()
    }
  }, [])

  useEffect(() => {
    validateRegistrationInvitedValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    acceptOrganisationInvite({ ...values, id: organisationInviteId })
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Metadata meta={META.signUp} />
      <FormTitle>
        {t('signUpPage.invitedTitle', {
          organisationName: withPossessiveS(
            pathOr('', ['organisation', 'name'])(organisationInvite)
          )
        })}
      </FormTitle>
      <Form onSubmit={handleSubmit}>
        <Input
          value={organisationInvite.email}
          disabled
          required
          name='email'
          label={t('form.email.label')}
        />
        <Input
          required
          name='password'
          type='password'
          label={t('form.password.label')}
          onChange={handleOnChange}
          validate={validateRegistrationInvitedField(values)}
        />
        <Input
          required
          name='firstName'
          label={t('form.firstName.label')}
          onChange={handleOnChange}
          validate={validateRegistrationInvitedField(values)}
        />
        <Input
          required
          name='lastName'
          label={t('form.lastName.label')}
          onChange={handleOnChange}
          validate={validateRegistrationInvitedField(values)}
        />
        <Select
          required
          options={jobFunctionOptions()}
          name='jobFunction'
          label={t('form.jobFunction.label')}
          validate={validateRegistrationInvitedField(values)}
          onChange={handleOnChange}
        />
        <ButtonContainer>
          <Button
            type='submit'
            disabled={isLoading || !valid}
            onClick={handleSubmit}
          >
            {t('signUpPage.registerCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default SignUpForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const FormTitle = styled.h3`
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 20px 0;
`
