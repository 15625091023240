import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { omit, pipe, merge } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import PATHS from 'src/utils/paths'
import { META } from 'src/utils/seo'
import { countryOptions } from 'src/utils/country'
import { jobFunctionOptions } from 'src/utils/jobFunction'
import {
  validateRegistrationField,
  validateRegistrationValues
} from 'src/features/auth/duck/schema'

import { registerUserRoutine } from 'src/features/auth/duck/actions'
import {
  getRegistrationEmail,
  isAuthLoading
} from 'src/features/auth/duck/selectors'

import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Select from 'src/components/atoms/Select'
import Metadata from 'src/components/Metadata'
import Button from 'src/components/atoms/Button'

const emptyValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  organisationName: '',
  organisationCountry: '',
  jobFunction: ''
}

export const SignUpForm = props => {
  const { navigate } = props
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isAuthLoading)
  const registrationEmail = useSelector(getRegistrationEmail)
  const registerUser = useCallback(
    payload => dispatch(registerUserRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    isNotNilOrEmpty(registrationEmail) &&
      handleOnChange('email', registrationEmail)
    !isNotNilOrEmpty(registrationEmail) && navigate(PATHS.signUp)
  }, [registrationEmail])

  useEffect(() => {
    validateRegistrationValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    const prepareOrganisationValues = {
      organisation: {
        name: values.organisationName,
        country: values.organisationCountry
      }
    }

    const payload = pipe(
      omit(['organisationName', 'organisationCountry']),
      merge(prepareOrganisationValues)
    )(values)

    registerUser(payload)
  }

  return (
    <>
      <Metadata meta={META.signUp} />
      <Form onSubmit={handleSubmit}>
        <Input
          value={registrationEmail}
          disabled
          required
          name='email'
          label={t('form.email.label')}
        />
        <Input
          required
          name='password'
          type='password'
          label={t('form.password.label')}
          onChange={handleOnChange}
          validate={validateRegistrationField(values)}
        />
        <Input
          required
          name='firstName'
          label={t('form.firstName.label')}
          onChange={handleOnChange}
          validate={validateRegistrationField(values)}
        />
        <Input
          required
          name='lastName'
          label={t('form.lastName.label')}
          onChange={handleOnChange}
          validate={validateRegistrationField(values)}
        />
        <Input
          required
          name='organisationName'
          label={t('form.organisationName.label')}
          validate={validateRegistrationField(values)}
          onChange={handleOnChange}
        />
        <Select
          required
          options={countryOptions()}
          name='organisationCountry'
          label={t('form.country.label')}
          validate={validateRegistrationField(values)}
          onChange={handleOnChange}
        />
        <Select
          required
          options={jobFunctionOptions()}
          name='jobFunction'
          label={t('form.jobFunction.label')}
          validate={validateRegistrationField(values)}
          onChange={handleOnChange}
        />
        <ButtonContainer>
          <Button
            type='submit'
            disabled={isLoading || !valid}
            onClick={handleSubmit}
          >
            {t('signUpPage.registerCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default SignUpForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
