import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { META } from 'src/utils/seo'
import PATHS from 'src/utils/paths'
import { hasEmailAnInvitationSent } from 'src/utils/form'
import {
  validateRegistrationInitField,
  validateRegistrationInitValues
} from 'src/features/auth/duck/schema'

import { setRegistrationEmailRoutine } from 'src/features/auth/duck/actions'
import { isAuthLoading } from 'src/features/auth/duck/selectors'

import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Metadata from 'src/components/Metadata'
import Button from 'src/components/atoms/Button'

const emptyValues = {
  email: ''
}

export const SignUpForm = props => {
  const { navigate } = props
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isAuthLoading)
  const setRegistrationEmail = useCallback(
    payload => dispatch(setRegistrationEmailRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [redirectPath, _setRedirectPath] = useState(PATHS.signUpNewUser)

  const getRedirectPath = async email => {
    const organisationInviteId = await hasEmailAnInvitationSent(email)
    return _setRedirectPath(
      organisationInviteId
        ? `/sign-up/invite/${organisationInviteId}`
        : PATHS.signUpNewUser
    )
  }

  useEffect(() => {
    validateRegistrationInitValues(values, _validateSchema)
    getRedirectPath(values.email)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    setRegistrationEmail(values.email)
    await navigate(redirectPath)
  }

  return (
    <>
      <Metadata meta={META.signUp} />
      <Form onSubmit={handleSubmit}>
        <Input
          required
          name='email'
          label={t('form.email.label')}
          onChange={handleOnChange}
          validate={validateRegistrationInitField(values)}
        />
        <ButtonContainer>
          <Button
            type='submit'
            disabled={isLoading || !valid}
            onClick={handleSubmit}
          >
            {t('signUpPage.continue')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default SignUpForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
