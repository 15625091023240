import React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from 'src/theme/Layout'
import { Router } from '@reach/router'
import PATHS from 'src/utils/paths'
import BubbleBackground from 'src/components/BubbleBackground'
import VerticalAlignContainer from 'src/components/VerticalAlignContainer'

import Paper from 'src/components/atoms/Paper'
import PaperTitle from 'src/components/atoms/PaperTitle'
import AuthFormContainer from 'src/components/AuthFormContainer'
import SignUpInit from 'src/features/auth/SignUpInit'
import SignUpNewUser from 'src/features/auth/SignUpNewUser'
import SignUpInvitedUser from 'src/features/auth/SignUpInvitedUser'

export const SignUp = props => {
  const { t } = useTranslation()

  return (
    <Layout>
      <BubbleBackground>
        <VerticalAlignContainer>
          <AuthFormContainer>
            <Paper>
              <PaperTitle>{t('signUpPage.title')}</PaperTitle>
              <Router>
                <SignUpInit path={PATHS.signUp} />
                <SignUpNewUser path={PATHS.signUpNewUser} />
                <SignUpInvitedUser path={PATHS.signUpInvitedUser} />
              </Router>
            </Paper>
          </AuthFormContainer>
        </VerticalAlignContainer>
      </BubbleBackground>
    </Layout>
  )
}

export default SignUp
